import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import CastellanLogo from './CastellanLogo';

const APP_LINK_URL = 'https://app.castellan.co';

const rootlink = css`
  font-size: 17px;
  color: rgba(255,255,255,1) !important;

  &:hover {
    color: rgba(255,255,255,0.7) !important;
  }
`

const RightArrow = () => <FontAwesomeIcon icon={faArrowRight} />

const DesktopNavbar = ({transparent, onNavToggle }) => (
  <nav className={`navbar navbar-expand-lg ${(transparent === true) ? "navbar-transparent" : "bg-primary custom-navbar"} navbar-dark navbar-expand-md`}>
    <div className="container" >
    <Link css={rootlink} className="navbar-brand" to="/">
      <CastellanLogo />
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-target="#stage"
      data-toggle="stage"
      onClick={onNavToggle}
      data-distance="-250">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse">
      <ul className='navbar-nav mr-auto ml-auto' >
      <li className="nav-item">
          <Link css={rootlink} className="nav-link" to="/presence" activeClassName="active">Presence</Link>
      </li>
      <li className="nav-item">
          <Link css={rootlink} className="nav-link" to="/booking" activeClassName="active">Booking</Link>
      </li>
      {/*
      <li className="nav-item">
          <Link css={rootlink} className="nav-link" to="/social">Social</Link>
      </li>
      */}
        </ul>
      <ul className='navbar-nav' >
      {/*
        <li className="nav-item px-1 ">
          <Link css={rootlink} className="nav-link" to="#">Support</Link>
        </li>
      */}
        <li className="nav-item px-1 ">
          <a css={rootlink} className="nav-link" href={APP_LINK_URL}>Sign in <RightArrow /></a>
        </li>
      </ul>
    </div>
    </div>
  </nav>
)

export default DesktopNavbar;