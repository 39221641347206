import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby';

const RightArrow = () => <FontAwesomeIcon icon={faArrowRight} />

const MobileNavbar = () => (
<div className="stage-shelf stage-shelf-right hidden" id="sidebar">
  <ul className="nav nav-bordered nav-stacked flex-column">
    <li className="nav-item">
      <Link className="nav-link" to="/" activeClassName="active">Home</Link>
    </li>
    <li className="nav-header">Products</li>
    <li className="nav-item">
      <Link className="nav-link" to="/presence" activeClassName="active" >Presence</Link>
    </li>
    <li className="nav-item">
      <Link className="nav-link" to="/booking" activeClassName="active">Booking</Link>
    </li>
    {/*
    <li className="nav-item">
      <a className="nav-link" href="bold/index.html">Social</a>
    </li>
    */}
    <li className="nav-divider"></li>
    <li className="nav-header">Navigation</li>
    <li className="nav-item">
      <Link className="nav-link" to="/contact" activeClassName="active">Contact</Link>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="https://app.castellan.co">Sign in <RightArrow /></a>
    </li>
  </ul>
</div>
)
export default MobileNavbar;