import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
const year = "2021";

const cssSocialLinks = css`
    padding-right: 1rem;
    color: rgba(255, 255, 255, 0.5);
    &:hover {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
    }
 
`
const cssFooter = css`
    background: #262F36;
`

const cssFullFooter = css` 
    background: #262F36;
    padding: 65px 0 55px;
`

const cssFooterLinks = css`
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
    &:hover {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
    }
`



const TwitterIcon = () => <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
const FBIcon = () => <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
const LinkedInIcon = () => <FontAwesomeIcon icon={faLinkedin} size="2x" />

export const MinimalFooter = () => (
<footer css={cssFooter} >
    <nav className="navbar navbar-dark navbar-expand-md">
    <div className="container">
    <div className="navbar-collapse">
      <ul className='navbar-nav mr-auto' >
        <li className="nav-item px-1 ">
          <Link className="nav-link" to="/contact">Contact</Link>
        </li>
        <li className="nav-item px-1 ">
          <a className="nav-link" href="https://www.iubenda.com/privacy-policy/8252473">Privacy &amp; Terms</a>
        </li>
        <li className="nav-item px-1 navbar-text">Copyright © {year} Keepium</li>
      </ul>
      <ul className='navbar-nav' >
        <li className="nav-item px-1 ">
          <a css={cssSocialLinks} href="https://www.facebook.com/CastellanLiving/" aria-label="Facebook Page"><FBIcon /></a>
          <a css={cssSocialLinks} href="https://twitter.com/castellanLiving" aria-label="Twitter Account"><TwitterIcon /></a>
          <a css={cssSocialLinks} href="https://www.linkedin.com/company/castellanliving" aria-label="LinkedIn Page"><LinkedInIcon /></a>
        </li>
      </ul>
      </div>
    </div>
    </nav>
</footer>
);

const FullFooter = (props) => (
<footer css={cssFullFooter} >
 <div className="container">
    <div className="row">
      <div className="col-md-5 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase">About</h6></li>
          <li className="text-muted">
             Our mission is to create buildings that learn and adapt to their residents to provide a next generation living experience.
          </li>
          <li className="text-muted">Copyright © {year} Keepium</li>
          <li>
            <a css={cssSocialLinks} href="https://www.facebook.com/CastellanLiving/" aria-label="Facebook Page"><FBIcon /></a>
            <a css={cssSocialLinks} href="https://twitter.com/castellanLiving" aria-label="Twitter Account"><TwitterIcon /></a>
            <a css={cssSocialLinks} href="https://www.linkedin.com/company/castellanliving" aria-label="LinkedIn Page"><LinkedInIcon /></a>
          </li>
        </ul>
      </div>
      <div className="col-md-2 ml-auto mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase">Products</h6></li>
          <li>
            <Link css={cssFooterLinks} to="/presence">Presence</Link>
          </li>
          <li>
            <Link css={cssFooterLinks} to="/booking">Booking</Link>
          </li>
          {/*
          <li>
            <Link css={cssFooterLinks} to="/social">Social</Link>
          </li>
          */}
        </ul>
      </div>
      <div className="col-md-2 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase">Company</h6></li>
          <li>
            <Link css={cssFooterLinks} to="/about">About</Link>
          </li>
          <li>
            <a css={cssFooterLinks} href="https://blog.castellan.co">Blog</a>
          </li>
        </ul>
      </div>
      <div className="col-md-2 mb-5">
        <ul className="list-unstyled list-spaced">
          <li className="mb-2"><h6 className="text-uppercase">Resources</h6></li>
          <li>
            <a css={cssFooterLinks} href="https://www.iubenda.com/privacy-policy/8252473">Privacy &amp; Terms</a>
          </li>
          <li>
            <Link css={cssFooterLinks} to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </footer>
);

export default FullFooter;