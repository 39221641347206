import React, { Component } from 'react'
import posed from 'react-pose';


const StageDiv = posed.div({
    closed: {
         x: 0,
        transition: {
            duration: 300,
            easing: [.2,.7,.5,1],
        },
        },
    open: {
         x: -250,
        transition: {
            duration: 300,
            easing: [.2,.7,.5,1],
        },
    },
});

class Stage extends Component {

    render() {

        const { children, isOpen } = this.props;
        const pose = isOpen ? "open" : "closed"
        return (
            <StageDiv pose={pose} className="stage" id="stage">
                {children}
            </StageDiv>
        )
    }
}

export default Stage;